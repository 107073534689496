import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box, Center, Divider, Text, IconButton } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { motion } from 'framer-motion';
// @ts-ignore
import construction1 from "../assets/photo_bat/construction1.jpg";
// @ts-ignore
import construction2 from "../assets/photo_bat/construction2.jpg";
// @ts-ignore
import construction3 from "../assets/photo_bat/construction3.jpg";
// @ts-ignore
import construction5 from "../assets/photo_bat/construction5.jpg";
// @ts-ignore
import construction6 from "../assets/photo_bat/construction6.jpg";
// @ts-ignore
import construction7 from "../assets/photo_bat/construction7.jpg";
import { useTranslation } from 'react-i18next';
import React from "react";

const Realisations = () => {
    const { t } = useTranslation();

    const rotateAnimation = {
        hidden: { opacity: 0, rotateY: 90 },
        visible: {
            opacity: 1,
            rotateY: 0,
            transition: { type: "spring", stiffness: 50, damping: 20, duration: 0.8 },
        },
        exit: { opacity: 0, rotateY: -90, transition: { duration: 0.6 } }
    };

    return (
        <Box id="Produits" textAlign="center" bg="#003552" color="white" py={8} px={4}>
            <Text fontSize={{ base: "4xl", md: "6xl" }} color="white">
                {t('realisationTitre')}
            </Text>
            <Center>
                <Divider orientation='horizontal' w="50%" borderColor="whiteAlpha.700" />
            </Center>

            <Box maxW="1000px" mx="auto" my={8} boxShadow="lg" borderRadius="lg" overflow="hidden">
                <Box borderWidth="2px" borderRadius="lg" overflow="hidden" margin={4} boxShadow="2xl" borderColor="whiteAlpha.300">
                    <Carousel
                        showArrows={true}
                        infiniteLoop={true}
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={true}
                        interval={3000}
                        transitionTime={600}
                        renderArrowPrev={(clickHandler, hasPrev) => (
                            <IconButton
                                aria-label="Précédent"
                                icon={<FaArrowLeft/>}
                                onClick={clickHandler}
                                position="absolute"
                                left="10px"
                                top="50%"
                                transform="translateY(-50%)"
                                zIndex="10"
                                colorScheme="blue"
                                _hover={{transform: 'scale(1.2)', bg: 'blue.500'}}
                                _focus={{boxShadow: "none"}}
                                isDisabled={!hasPrev}
                                borderRadius="full"
                                size="lg"
                            />
                        )}
                        renderArrowNext={(clickHandler, hasNext) => (
                            <IconButton
                                aria-label="Suivant"
                                icon={<FaArrowRight/>}
                                onClick={clickHandler}
                                position="absolute"
                                right="10px"
                                top="50%"
                                transform="translateY(-50%)"
                                zIndex="10"
                                colorScheme="blue"
                                _hover={{transform: 'scale(1.2)', bg: 'blue.500'}}
                                _focus={{boxShadow: "none"}}
                                isDisabled={!hasNext}
                                borderRadius="full"
                                size="lg"
                            />
                        )}
                    >
                        <motion.div initial="hidden" animate="visible" exit="exit" variants={rotateAnimation}>
                            <Image src={construction1} alt="Construction 1" boxSize="100%" objectFit="cover"/>
                        </motion.div>
                        <motion.div initial="hidden" animate="visible" exit="exit" variants={rotateAnimation}>
                            <Image src={construction2} alt="Construction 2" boxSize="100%" objectFit="cover"/>
                        </motion.div>
                        <motion.div initial="hidden" animate="visible" exit="exit" variants={rotateAnimation}>
                            <Image src={construction3} alt="Construction 3" boxSize="100%" objectFit="cover"/>
                        </motion.div>
                        <motion.div initial="hidden" animate="visible" exit="exit" variants={rotateAnimation}>
                            <Image src={construction5} alt="Construction 5" boxSize="100%" objectFit="cover"/>
                        </motion.div>
                        <motion.div initial="hidden" animate="visible" exit="exit" variants={rotateAnimation}
                                    style={{height: "100%"}}>
                            <Image src={construction6} alt="Construction 6" height="100%" objectFit="cover"/>
                        </motion.div>
                        <motion.div initial="hidden" animate="visible" exit="exit" variants={rotateAnimation}
                                    style={{height: "100%"}}>
                            <Image src={construction7} alt="Construction 7" height="100%" objectFit="cover"/>
                        </motion.div>
                    </Carousel>
                </Box>
            </Box>
        </Box>
    );
};

export default Realisations;
