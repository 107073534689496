import React from 'react';
import { Box, Text, HStack, Button, Divider } from '@chakra-ui/react';

const ClientComponent: React.FC = () => {
    const options = [
        'Plant',
        'Devis',
        'Planning',
        'Historique',
    ];

    return (
        <Box
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
            p={4}
        >
            <Text
                position="absolute"
                top={4}
                right={4}
                fontSize="2xl"
                fontWeight="bold"
            >
                Bienvenue
            </Text>

            <Divider position="absolute" top={12} right={0} width="100%" borderColor="gray.200" />

            <HStack
                spacing={6}
                position="absolute"
                top={16}
                right={0}
                left={0}
                justifyContent="center"
                mt={4}
            >
                {options.map((option, index) => (
                    <Button
                        key={index}
                        variant="link"
                        color="gray.400"
                        _hover={{ textDecoration: 'underline' }}
                    >
                        {option}
                    </Button>
                ))}
            </HStack>

            <Button
                position="absolute"
                bottom={4}
                right={4}
                colorScheme="red"
                variant="solid"
                _hover={{ bg: 'red.600' }}
            >
                Logout
            </Button>
        </Box>
    );
};

export default ClientComponent;
