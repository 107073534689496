import React from "react";
import { Box } from "@chakra-ui/react";
import Nav from "../components/navigation/NavBar";
import AboutUs from "../components/AboutUs";
import Realisations from "../components/Realisations";
import Activity from "../components/Activities";
import Solutions from "../components/Solutions";
import Contact from "../components/Contact";
import Implantations from "../components/Implantations";
import MapSite from "../components/MapSite";
import Chargement from "../components/Chargement";
import Zebi from "../components/Zebi";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import SideDrawer from "../components/navigation/NavBarComponent";
import ScrollToTopButton from "../components/ScrollToTopButton";

export default function Home() {
    return (
        <I18nextProvider i18n={i18n}>
            <Box>
                <Chargement />
                <SideDrawer />
                <Box top="0" left="0" width="100%" zIndex="100" bg="white">
                    <Zebi />
                </Box>
                <Box mt="100vh">
                    <AboutUs />
                    <Realisations />
                    <Activity />
                    <Solutions />
                    <Contact />
                    <Implantations />
                    <MapSite />
                    <ScrollToTopButton/>
                </Box>
            </Box>
        </I18nextProvider>
    );
}
