import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
// @ts-ignore
import bg from '../assets/photo_bat/construction4.jpg';
import { useTranslation } from 'react-i18next';

const MotionBox = motion(Box);
const MotionText = motion(Text);

const Zebi = () => {
    const { t } = useTranslation();
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const zoom = 100 + scrollY * 0.1;

    const textVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: {
            opacity: 1,
            x: 0,
            transition: { type: "spring", stiffness: 50, duration: 1 },
        },
    };

    return (
        <MotionBox
            bgImage={bg}
            bgSize={`${zoom}%`}
            bgPosition="center"
            bgRepeat="no-repeat"
            minHeight="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            position="fixed"
            top="0"
            left="0"
            width="100%"
            zIndex="-1"
        >
            <MotionBox
                bg={useColorModeValue("rgba(255, 255, 255, 0.35)", "rgba(0, 0, 0, 0.3)")}
                width={"100%"}
                minHeight="100vh"
                position="relative"
                initial="hidden"
                animate="visible"
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                paddingRight="2rem"
            >
                <MotionText
                    fontSize={{ base: "4xl", md: "8xl" }}
                    textAlign="right"
                    color={useColorModeValue('#003552', 'white')}
                    mt={{ base: "20%", md: "10%" }}
                    mb={2}
                    variants={textVariants}
                    initial="hidden"
                    animate="visible"
                    _hover={{
                        "::after": {
                            transform: "scaleX(1)",
                        }
                    }}
                    sx={{
                        position: "relative",
                        display: "inline-block",
                        "::after": {
                            content: '""',
                            position: "absolute",
                            bottom: "-5px",
                            left: 0,
                            width: "100%",
                            height: "2px",
                            backgroundColor: useColorModeValue('#003552', 'white'),
                            transform: "scaleX(0)",
                            transformOrigin: "left",
                            transition: "transform 0.3s ease-in-out",
                        }
                    }}
                >
                    {t('Titre')}
                </MotionText>

                <MotionText
                    fontSize={{ base: "2xl", md: "5xl" }}
                    textAlign="right"
                    color={useColorModeValue('#003552', 'white')}
                    variants={textVariants}
                    initial="hidden"
                    animate="visible"
                    _hover={{
                        "::after": {
                            transform: "scaleX(1)",
                        }
                    }}
                    sx={{
                        position: "relative",
                        display: "inline-block",
                        "::after": {
                            content: '""',
                            position: "absolute",
                            bottom: "-5px",
                            left: 0,
                            width: "100%",
                            height: "2px",
                            backgroundColor: useColorModeValue('#003552', 'white'),
                            transform: "scaleX(0)",
                            transformOrigin: "left",
                            transition: "transform 0.3s ease-in-out",
                        }
                    }}
                >
                    {t('SousTitre')}
                </MotionText>
            </MotionBox>
        </MotionBox>
    );
};

export default Zebi;
