import { MenuButton, IconButton, Box, useDisclosure, Image } from "@chakra-ui/react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from 'react-i18next';
// @ts-ignore
import frFlag from "../../../assets/drapeaux/fr.svg";
// @ts-ignore
import usFlag from "../../../assets/drapeaux/gb.svg";
// @ts-ignore
import esFlag from "../../../assets/drapeaux/es.svg";

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState<string>(i18n.language);
    const { isOpen, onToggle, onClose } = useDisclosure();

    const handleLanguageSelect = (lang: string) => {
        i18n.changeLanguage(lang);
        setSelectedLang(lang);

        setTimeout(onClose, 500);
    };

    const flagImageForSelectedLanguage = (lang: string) => {
        switch (lang) {
            case 'en':
                return usFlag;
            case 'fr':
                return frFlag;
            case 'es':
                return esFlag;
            default:
                return frFlag;
        }
    };

    const languages = [
        { code: "en", label: "English" },
        { code: "fr", label: "Français" },
        { code: "es", label: "Español" }
    ];

    const animationVariants = {
        initial: { opacity: 0, scale: 0.8, y: -20 },
        animate: { opacity: 1, scale: 1, y: 0, transition: { duration: 0.4, ease: "easeInOut" }},
        exit: { opacity: 0, scale: 0.8, y: -10, transition: { duration: 0.4, ease: "easeInOut" }}
    };

    return (
        <Box position="relative" mr={2}>
            <IconButton
                icon={<Image src={flagImageForSelectedLanguage(selectedLang)} alt="Flag Icon" boxSize={6} />}
                bg="null"
                aria-label="Select Language"
                borderRadius="full"
                border="2px solid transparent"
                _hover={{ borderColor: "#032c3d" }}
                onClick={onToggle}
            />

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'absolute',
                            top: '60px',
                            left: '0',
                        }}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={animationVariants}
                    >
                        {languages
                            .filter(lang => lang.code !== selectedLang) // Ne pas afficher la langue sélectionnée
                            .map((lang) => (
                                <IconButton
                                    key={lang.code}
                                    icon={<Image src={flagImageForSelectedLanguage(lang.code)} alt={`${lang.label} Flag`} boxSize={6} />}
                                    aria-label={lang.label}
                                    borderRadius="full"
                                    mb={2}
                                    bg="null"
                                    onClick={() => handleLanguageSelect(lang.code)}
                                    border="2px solid transparent"
                                    _hover={{ borderColor: "#032c3d" }}
                                />
                            ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </Box>
    );
};

export default LanguageSelector;
