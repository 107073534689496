import React, { useState, useEffect } from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaArrowUp } from 'react-icons/fa';
import { motion } from 'framer-motion';

const ScrollToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Fonction pour gérer le scroll
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Fonction pour scroller vers le haut
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Ajout d'un listener pour détecter le scroll
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: isVisible ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            style={{
                position: 'fixed',
                bottom: '40px',
                right: '40px',
                zIndex: 1000,
            }}
        >
            {isVisible && (
                <IconButton
                    icon={<FaArrowUp />}
                    aria-label="Scroll to top"
                    colorScheme="blue"
                    onClick={scrollToTop}
                    borderRadius="full"
                    size="lg"
                    _hover={{ transform: 'scale(1.2)', bg: 'blue.500' }}
                    _focus={{ boxShadow: 'none' }}
                />
            )}
        </motion.div>
    );
};

export default ScrollToTopButton;
