import React, { useState, useEffect } from 'react';
import { infinity } from 'ldrs';

infinity.register();

const LoadingComponent = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const delay = setTimeout(() => {
            setIsLoading(false);
        }, 150);

        return () => clearTimeout(delay);
    }, []);

    if (isLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <l-infinity
                    size="55"
                    stroke="4"
                    stroke-length="0.15"
                    bg-opacity="0.1"
                    speed="1.3"
                    color="black"
                ></l-infinity>
            </div>
        );
    }

    return <div></div>;
};

export default LoadingComponent;
