import React, { useState, forwardRef } from "react";
import {
    IconButton,
    Box,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faBars } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import LanguageSelector from "./LanguageSelector";
import UserProfile from "./UserProfile";
import DarkMode from "../DarkMode";

const MotionIconButton = motion(
    forwardRef<HTMLButtonElement, any>((props, ref) => {
        const { "aria-label": ariaLabel, ...rest } = props;
        return <IconButton ref={ref} aria-label={ariaLabel} {...rest} />;
    })
);

const AnimatedMenu: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { isOpen: isProfileOpen, onOpen: onProfileOpen, onClose: onProfileClose } = useDisclosure();
    const { isOpen: isLanguageOpen, onOpen: onLanguageOpen, onClose: onLanguageClose } = useDisclosure();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
        exit: { opacity: 0 },
    };

    const itemVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: { opacity: 1, x: 0, transition: { type: "spring", stiffness: 50 } },
        exit: { opacity: 0, x: 50 },
    };

    return (
        <Box position="relative">
            <MotionIconButton
                bg="transparent"
                icon={<FontAwesomeIcon icon={faBars} />}
                aria-label="Menu"
                borderRadius="full"
                onClick={toggleMenu}
                border="2px solid transparent"
                _hover={{ borderColor: "#032c3d" }}
                animate={{
                    rotate: isOpen ? 90 : 0,
                    scale: isOpen ? 1.2 : 1,
                    borderColor: isOpen ? "#032c3d" : "transparent",
                }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
            />

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'absolute',
                            top: '0',
                            right: '60px',
                        }}
                        transition={{ duration: 0.3 }}
                    >
                        <motion.div variants={itemVariants}>
                            <DarkMode/>
                        </motion.div>

                        <motion.div variants={itemVariants}>
                            <LanguageSelector />
                        </motion.div>

                    </motion.div>
                )}
            </AnimatePresence>

            <Modal isOpen={isProfileOpen} onClose={onProfileClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <UserProfile />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isLanguageOpen} onClose={onLanguageClose} size="sm">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <LanguageSelector />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default AnimatedMenu;
