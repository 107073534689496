import { Box, Flex, Image, Text, Link, useColorModeValue, Stack, Divider, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
// @ts-ignore
import mail from "../assets/mail.png";
// @ts-ignore
import phone from "../assets/phone.png";
// @ts-ignore
import adresse from "../assets/adresse.png";
import React from "react";

const Contact = () => {
    const { t } = useTranslation();
    const [isSmallScreen] = useMediaQuery("(max-width: 768px)");
    const dividerOrientation = isSmallScreen ? "horizontal" : "vertical";
    const dividerHeight = isSmallScreen ? "2px" : "200px";

    return (
        <Box id="contact" textAlign="center" py={{ base: 8, md: 12 }} px={4} bg={useColorModeValue("white", "#1A202C")}>
            <Text fontSize={{ base: "4xl", md: "6xl" }} textAlign="center" color={useColorModeValue("#003552", "blue.200")}>
                {t('contactTitre')}
            </Text>
            <Box ml={10} />
            <Flex justify="center" flexWrap="wrap">
                <Box textAlign="center" mx={4} my={4} flex="1 0 300px">
                    <Image src={phone} alt="Image 1" style={{ margin: "0 auto" }} boxSize='100px' />
                    <Box ml={10} />
                    <Text fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                        {t('contactTet')}
                    </Text>
                    <Box ml={10} />
                    <Link fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                        {t('contactTelnum')}
                    </Link>
                </Box>
                <Stack direction="row" h={dividerHeight} p={4}>
                    <Divider orientation={dividerOrientation} borderColor="gray.300" />
                </Stack>
                <Box textAlign="center" mx={4} my={4} flex="1 0 300px">
                    <Image src={adresse} alt="Image 3" style={{ margin: "0 auto" }} boxSize='100px' />
                    <Box ml={10} />
                    <Text fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                        {t('contactadress')}
                    </Text>
                    <Box ml={10} />
                    <Text fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                        {t('contactadressnum')}
                    </Text>
                </Box>
                <Stack direction="row" h={dividerHeight} p={4}>
                    <Divider orientation={dividerOrientation} borderColor="gray.300" />
                </Stack>
                <Box textAlign="center" mx={4} my={4} flex="1 0 300px">
                    <Box textAlign="center" flexDirection="column">
                        <Image src={mail} alt="" style={{ margin: "0 auto" }} boxSize='100px' />
                        <Box ml={10} />
                        <Text fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                            {t('contactmail')}
                        </Text>
                        <Box ml={10} />
                        <Text fontSize="xl" color={useColorModeValue('#003552', 'blue.200')}>
                            {t('contactmailnum')}
                        </Text>
                    </Box>
                </Box>
            </Flex>
            <Divider mt={4} borderColor="gray.300" />
        </Box>
    );
};

export default Contact;
