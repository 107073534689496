import { Box, Flex, Center, Image, Text, useColorModeValue, Divider, Stack, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React, {useRef, useState} from 'react';
// @ts-ignore
import construction from "../assets/construction.png";
// @ts-ignore
import boutique from "../assets/boutique.png";

const Activity = () => {
    const { t } = useTranslation();
    const [isSmallScreen] = useMediaQuery("(max-width: 768px)");
    const dividerOrientation = isSmallScreen ? "horizontal" : "vertical";
    const shouldDisplayDivider = typeof window !== "undefined" && window.innerWidth >= 768;

    const [rotation, setRotation] = useState({ rotateX: 0, rotateY: 0 });
    const [isHovered, setIsHovered] = useState(false);
    const animationFrameId = useRef<number | null>(null);
    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isHovered) return;

        const { clientX, clientY, currentTarget } = event;
        const { left, top, width, height } = currentTarget.getBoundingClientRect();
        const x = Math.max(Math.min(((clientX - left) / width - 0.5) * 12, 12), -12);
        const y = Math.max(Math.min(((clientY - top) / height - 0.5) * 12, 12), -12);

        if (animationFrameId.current) cancelAnimationFrame(animationFrameId.current);

        animationFrameId.current = requestAnimationFrame(() => {
            setRotation({ rotateX: y, rotateY: -x });
        });
    };

    return (
        <Flex
            id="Services"
            direction="column"
            align="center"
            justify="center"
            py={12}
            bg={useColorModeValue("#F9F9F9", "#1A202C")}
        >
            <Center>
                <Text fontSize={{ base: "3xl", md: "5xl" }} textAlign="center" color={useColorModeValue("#003552", "blue.200")} py={8}>
                    {t('activitiTitre')}
                </Text>
            </Center>

            <Flex
                direction={{ base: "column", md: "row" }}
                align="center"
                justify="center"
                width="100%"
                maxWidth="1200px"
                px={6}
                py={6}
                border="1px solid #e2e8f0"
                boxShadow="lg"
                bg={useColorModeValue("white", "gray.800")}
                borderRadius="lg"
                sx={{ transformStyle: "preserve-3d" }}
                transform={isHovered ? `rotateX(${rotation.rotateX}deg) rotateY(${rotation.rotateY}deg)` : "none"}
                transition="transform 0.8s ease-out"
                onMouseMove={handleMouseMove}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => {
                    setIsHovered(false);
                    setRotation({ rotateX: 0, rotateY: 0 });
                }}
            >
                <Box width={{ base: "100%", md: "50%" }} my={{ base: 4, md: 0 }} textAlign="center" px={4}>
                    <Image src={construction} alt="Construction Image" boxSize="100px" mx="auto" />
                    <Text fontSize={{ base: "xl", md: "2xl" }} color={useColorModeValue('#003552', 'blue.200')} mt={4} fontWeight="semibold">
                        {t('activitiTitrecons')}
                    </Text>
                    <Text align="center" color={useColorModeValue('#003552', 'blue.200')} mt={2}>
                        <p>{t('activitiTextecons')}</p>
                        <p>{t('activitiTextecons1')}</p>
                        <p>{t('activitiTextecons2')}</p>
                        <p>{t('activitiTextecons3')}</p>
                    </Text>
                </Box>

                {shouldDisplayDivider && (
                    <Stack direction="row" h="300px" p={4}>
                        <Divider orientation={dividerOrientation} order={2} />
                    </Stack>
                )}

                <Box width={{ base: "100%", md: "50%" }} my={{ base: 4, md: 0 }} textAlign="center" px={4}>
                    <Image src={boutique} alt="Boutique Image" boxSize="100px" mx="auto" />
                    <Text fontSize={{ base: "xl", md: "2xl" }} color={useColorModeValue('#003552', 'blue.200')} mt={4} fontWeight="semibold">
                        {t('activitiTitrevent')}
                    </Text>
                    <Text align="center" color={useColorModeValue('#003552', 'blue.200')} mt={2}>
                        {t('activitiTextevent')}
                    </Text>
                </Box>
            </Flex>
        </Flex>
    );
};

export default Activity;
