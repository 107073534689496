import React from 'react';
import { Box, Text, VStack, Button } from '@chakra-ui/react';

const EmployeeComponent: React.FC = () => {
    return (
        <Box p={4} textAlign="center">
            <Text fontSize="2xl" fontWeight="bold">Bienvenue, cher employé !</Text>
            <Text mb={4}>Accédez à vos outils de gestion :</Text>
            <VStack spacing={3}>
                <Button colorScheme="blue">Gérer les projets</Button>
                <Button colorScheme="blue">Accéder aux outils internes</Button>
                <Button colorScheme="blue">Consulter les statistiques</Button>
            </VStack>
        </Box>
    );
};

export default EmployeeComponent;
