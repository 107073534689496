import { Box, Text, useColorModeValue, Flex, Link, Divider, Stack } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import React from "react";

const MapSite = () => {
    const { t } = useTranslation();
    const bgColor = useColorModeValue("white", "#f7f7f7");
    const textColor = useColorModeValue('#4A5568', '#A0AEC0');
    const dividerColor = useColorModeValue('gray.300', 'gray.600');
    const isDesktop = typeof window !== 'undefined' && window.innerWidth >= 768;

    return (
        <Box bg={bgColor} py={8} px={6} textAlign="left">
            <Flex justify="space-around" flexWrap="wrap" alignItems="center">
                {/* Section Entreprise */}
                <Box mx={2} my={4} flex="1 0 250px">
                    <Text color={textColor} fontSize="lg" fontWeight="semibold">
                        {t('MapsitEntreprise')}
                    </Text>
                    <Text color={textColor} fontSize="sm" mt={2}>
                        {t('MapsitHMP')}
                    </Text>
                    <Text color={textColor} fontSize="sm">
                        {t('MapsitText')}
                    </Text>
                    <Text color={textColor} fontSize="sm">
                        {t('Mapsitadressnum')}
                    </Text>
                </Box>

                {isDesktop && <Divider orientation="vertical" height="120px" borderColor={dividerColor} />}

                {/* Section Contact */}
                <Box mx={2} my={4} flex="1 0 250px">
                    <Text color={textColor} fontSize="lg" fontWeight="semibold">
                        {t('Mapsitcontact')}
                    </Text>
                    <Text color={textColor} fontSize="sm" mt={2}>
                        {t('Mapsitmailnum')}
                    </Text>
                    <Text color={textColor} fontSize="sm">
                        {t('MapsitTelnum')}
                    </Text>
                </Box>

                {isDesktop && <Divider orientation="vertical" height="120px" borderColor={dividerColor} />}

                {/* Section Liens */}
                <Box mx={2} my={4} flex="1 0 250px">
                    <Text color={textColor} fontSize="lg" fontWeight="semibold">
                        {t('MapsitPDS')}
                    </Text>
                    <Stack spacing={1} mt={4} textAlign="left">
                        <Link>
                            <Text color={textColor} fontSize="sm">{t('MapsitAccueil')}</Text>
                        </Link>
                        <Link>
                            <Text color={textColor} fontSize="sm">{t('MapsitServices')}</Text>
                        </Link>
                        <Link>
                            <Text color={textColor} fontSize="sm">{t('MapsitProduits')}</Text>
                        </Link>
                    </Stack>
                </Box>
            </Flex>

            {isDesktop && <Divider mt={8} borderColor={dividerColor} />}

            <Box mt={4}>
                <Text color={textColor} fontSize="xs">
                    © {new Date().getFullYear()} {t('MapsitHMP')}. Tous droits réservés.
                </Text>
            </Box>
        </Box>
    );
};

export default MapSite;
