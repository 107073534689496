import React from "react";
import { Button, Image, Text, useColorModeValue, HStack } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
// @ts-ignore
import logo from "../../../assets/logo.png";

const HomeButton: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = () => {
        navigate('/');
        window.location.reload();  // Recharge la page après la navigation
    };

    return (
        <Button
            aria-label="Go to Home"
            onClick={handleClick}
            mr={4}
            fontFamily="serif"
            color="#032c3d"
            fontWeight="bold"
            fontSize="2xl"
            variant="unstyled"
            _hover={{ transform: "scale(1.1)" }}
            transition="transform 0.2s ease-in-out"
        >
            <HStack spacing={2} align="center">
                <Image src={logo} alt="Logo" boxSize={8} />
                <Text color={useColorModeValue("#003552", "blue.200")} fontSize="xl">
                    {t('Titre')}
                </Text>
            </HStack>
        </Button>
    );
};

export default HomeButton;
