import { Box, Flex, BoxProps, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import { motion, MotionProps } from "framer-motion";
import HomeButton from "./HomeButton/HomeButton";
import NavLinks from "./LinksButton/NavLinks";
import UserMenu from "./MenuButton/UserMenu";
import HomeButtonMobil from "./HomeButton/HomeButtonMobil";
import AnimatedMenuPhone from "./MenuButton/UserMenuPhone";

type MotionBoxProps = BoxProps & MotionProps;

const MotionBox = motion<BoxProps>(Box);

const SideDrawer: React.FC = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3,
                delayChildren: 0.2,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, x: -50 },
        visible: {
            opacity: 1,
            x: 0,
            transition: { type: "spring", stiffness: 50 },
        },
    };

    const bgColor = useColorModeValue("rgba(255, 255, 255, 0.8)", "rgba(0, 0, 0, 0.8)");

    const [isMobile] = useMediaQuery("(max-width: 1284px)");

    return (
        <MotionBox
            bg={bgColor}
            px={4}
            position="fixed"
            top={0}
            left={0}
            right={0}
            zIndex={1000}
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <Flex h={16} alignItems="center" justifyContent="space-between">
                {isMobile ? (
                    <Flex alignItems="center" flexDirection="row" justifyContent="space-between" w="100%">
                        <MotionBox variants={itemVariants}>
                            <HomeButtonMobil/>
                        </MotionBox>
                    </Flex>

                ) : (
                    <>
                        <MotionBox variants={itemVariants}>
                            <HomeButton />
                        </MotionBox>
                        <MotionBox variants={itemVariants}>
                            <NavLinks />
                        </MotionBox>
                    </>
                )}

                <MotionBox variants={itemVariants}>
                    {isMobile ? <AnimatedMenuPhone /> : <UserMenu />}
                </MotionBox>
            </Flex>
        </MotionBox>
    );
};

export default SideDrawer;
