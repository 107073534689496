import React, { useState } from "react";
import { Button, IconButton, Image, Text, useColorModeValue, HStack, VStack, Link } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from "framer-motion";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
// @ts-ignore
import logo from "../../../assets/logo.png";

const HomeButtonMobil: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Appel du hook useColorModeValue pour le fond de la liste et les couleurs des boutons
    const backgroundColor = useColorModeValue("rgba(255, 255, 255, 0.9)", "rgba(0, 0, 0, 0.7)");
    const buttonHoverColor = useColorModeValue("blue.200", "blue.200"); // Bleu en mode sombre et clair
    const buttonTextColor = useColorModeValue("#032c3d", "blue.700");   // Texte bleu en mode sombre

    const navLinks = [
        { label: t("navBaraboutUs"), href: "#aboutUs" },
        { label: t("navBarProduits"), href: "#Produits" },
        { label: t("navBarServices"), href: "#Services" },
        { label: t("navBarcontact"), href: "#contact" },
    ];

    const itemVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
        exit: { opacity: 0, y: -20, transition: { duration: 0.3 } },
    };

    return (
        <>
            <HStack spacing={2} align="center">
                <Button
                    aria-label="Reload Page"
                    onClick={() => window.location.reload()}
                    mr={4}
                    fontFamily="serif"
                    color="#032c3d"
                    fontWeight="bold"
                    fontSize="2xl"
                    variant="unstyled"
                    _hover={{ transform: "scale(1.1)" }}
                    transition="transform 0.2s ease-in-out"
                >
                    <HStack spacing={2} align="center">
                        <Image src={logo} alt="Logo" boxSize={8} />
                        <Text color={useColorModeValue("#003552", "blue.200")} fontSize="xl">
                            {t('Titre')}
                        </Text>
                    </HStack>
                </Button>

                <IconButton
                    aria-label="Toggle Menu"
                    icon={isOpen ? <FaChevronUp /> : <FaChevronDown />}
                    variant="ghost"
                    bg="null"
                    borderRadius="full"
                    border="2px solid transparent"
                    _hover={{ borderColor: "#032c3d" }}
                    onClick={toggleMenu}
                />
            </HStack>

            <AnimatePresence>
                {isOpen && (
                    <VStack
                        as={motion.div}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        spacing={6}
                        align="start"
                        position="absolute"
                        top="64px"
                        left="10px"
                        zIndex={999}
                        bg={backgroundColor}
                        p={5}
                        boxShadow="md"
                    >
                        {navLinks.map((link, index) => (
                            <motion.div
                                key={link.label}
                                variants={itemVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{ delay: index * 0.1 }} // Animation décalée pour chaque lien
                            >
                                <Button
                                    as={Link}
                                    href={link.href}
                                    w="100%"
                                    textAlign="left"
                                    variant="ghost"
                                    color={buttonTextColor} // Utilisation du texte en fonction du mode
                                    fontSize="lg"
                                    fontWeight="medium"
                                    _hover={{
                                        bg: buttonHoverColor,
                                        color: "white",
                                        transform: "scale(1.05)",
                                        transition: "all 0.2s ease-in-out",
                                    }}
                                >
                                    {link.label}
                                </Button>
                            </motion.div>
                        ))}
                    </VStack>
                )}
            </AnimatePresence>
        </>
    );
};

export default HomeButtonMobil;
