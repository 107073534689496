import React, { useState } from 'react';
import {
    VStack,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerBody,
    useDisclosure,
    IconButton,
    Input,
    Box,
    Text,
    Button,
} from '@chakra-ui/react';
import { FaUser, FaCircle, FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import ClientComponent from './ClientComponent';
import EmployeeComponent from './EmployeeComponent';

const MotionInput = motion(Input);
const MotionButton = motion(Button);

const usersDatabase = [
    { id: 'client123', password: 'client123', type: 'client' },
    { id: 'employee456', password: 'employee456', type: 'employee' },
];

const UserProfileDrawer: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showForm, setShowForm] = useState(false);
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [drawerSize, setDrawerSize] = useState('xs');
    const [userType, setUserType] = useState<string | null>(null); // Type d'utilisateur connecté

    const handleCircleClick = () => {
        setShowForm(true);
    };

    const dropAnimation = {
        hidden: { scale: 0, opacity: 0 },
        visible: {
            scale: 1,
            opacity: 1,
            transition: { type: "spring", stiffness: 60, damping: 12 }
        },
    };

    const areBothFieldsFilled = id !== '' && password !== '';

    const handleLogin = () => {
        const user = usersDatabase.find(user => user.id === id && user.password === password);

        if (user) {
            setUserType(user.type); // Définit le type d'utilisateur
            if (user.type === 'client') {
                setDrawerSize('md'); // Drawer de taille moyenne pour les clients
            } else if (user.type === 'employee') {
                setDrawerSize('lg'); // Drawer plus grand pour les employés
            }
        } else {
            alert('Identifiant ou mot de passe incorrect');
        }
    };

    return (
        <>
            <IconButton
                icon={<FaUser />}
                aria-label="Open Profile"
                onClick={onOpen}
                bg="transparent"
                borderRadius="full"
                border="2px solid transparent"
                _hover={{ borderColor: "#032c3d" }}
            />

            {isOpen && (
                <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={drawerSize}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerBody>
                            <VStack spacing={4} align="center" justify="center" height="100vh">
                                {!userType && (
                                    <>
                                        <Text fontSize="lg" fontWeight="bold">Êtes-vous déjà client ?</Text>
                                        <IconButton
                                            icon={<FaCircle />}
                                            aria-label="Click for ID and Password"
                                            onClick={handleCircleClick}
                                            bg="gray.300"
                                            borderRadius="full"
                                            size="lg"
                                            _hover={{ transform: "scale(1.2)", bg: "gray.400" }}
                                        />

                                        {showForm && (
                                            <Box
                                                p={4}
                                                mt={4}
                                                w="100%"
                                            >
                                                <VStack spacing={4}>
                                                    <MotionInput
                                                        placeholder="ID"
                                                        value={id}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setId(e.target.value)}
                                                        variants={dropAnimation}
                                                        initial="hidden"
                                                        animate="visible"
                                                    />
                                                    <MotionInput
                                                        placeholder="Mot de passe"
                                                        type="password"
                                                        value={password}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                                        variants={dropAnimation}
                                                        initial="hidden"
                                                        animate="visible"
                                                        transition={{ delay: 0.2 }}
                                                    />
                                                    <MotionButton
                                                        variants={dropAnimation}
                                                        initial="hidden"
                                                        animate="visible"
                                                        transition={{ delay: 0.4 }}
                                                        rightIcon={<FaArrowRight />}
                                                        borderRadius="full"
                                                        border="2px solid black"
                                                        bg="transparent"
                                                        _hover={{ bg: "gray.100" }}
                                                        _active={{ bg: "gray.200" }}
                                                        isDisabled={!areBothFieldsFilled}
                                                        onClick={handleLogin}
                                                    >
                                                        Se connecter
                                                    </MotionButton>
                                                </VStack>
                                            </Box>
                                        )}
                                    </>
                                )}

                                {/* Afficher les composants selon le type d'utilisateur */}
                                {userType === 'client' && <ClientComponent />}
                                {userType === 'employee' && <EmployeeComponent />}
                            </VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            )}
        </>
    );
};

export default UserProfileDrawer;
