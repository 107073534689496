import * as React from 'react';
import { FiSun, FiMoon } from 'react-icons/fi';
import { useColorMode, IconButton } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

export default function DarkMode() {
    const { colorMode, toggleColorMode } = useColorMode();
    const isDarkMode = colorMode === 'dark';

    const handleToggle = () => {
        toggleColorMode();
    };

    return (
        <IconButton
            mr={2}
            aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
            icon={
                <AnimatePresence mode="wait" initial={false}>
                    {isDarkMode ? (
                        <motion.div
                            key="sun"
                            initial={{ rotate: 90, opacity: 0 }}
                            animate={{ rotate: 0, opacity: 1 }}
                            exit={{ rotate: -90, opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <FiSun size={24} />
                        </motion.div>
                    ) : (
                        <motion.div
                            key="moon"
                            initial={{ rotate: -90, opacity: 0 }}
                            animate={{ rotate: 0, opacity: 1 }}
                            exit={{ rotate: 90, opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <FiMoon size={24} />
                        </motion.div>
                    )}
                </AnimatePresence>
            }
            onClick={handleToggle}
            borderRadius="full"
            bg="null"
            border="2px solid transparent"
            _hover={{ borderColor: "#032c3d" }}
            transition="transform 0.2s ease-in-out"
            _focus={{ boxShadow: "none" }}
        />
    );
}
