import React, {useState} from "react";
import {
    Box,
    Text,
    Button,
    Flex,
    Divider,
    useColorModeValue,
    Center,
    Modal,
    ModalContent,
    ModalBody,
    ModalOverlay
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import Pform from "./form";


const AboutUs = () => {
    const { t} = useTranslation();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const handleShowLoginModal = () => {
        setShowLoginModal(true);
    };
    const handleCloseLoginModal = () => {
        setShowLoginModal(false);
    };

    return (
        <Flex bg={useColorModeValue("#FFFFFF", "#1A202C")} id="aboutUs" direction="column" align="center" justify="center" >
            <Box textAlign="center"  p={4} rounded="md" maxW="1000px" mx="auto">
                <Text fontSize={{base: '3xl', md: '6xl'}} textAlign="center" color={useColorModeValue("#003552", "blue.200")}>
                    {t('aboutUsTitle')}
                </Text>
                <Divider />
                <Center  height="50px">
                    <Divider orientation="vertical" variant="thick" size="xl" />
                </Center>
                <Text fontSize={{base: '1xs', md: 'lg'}} color={useColorModeValue('#003552', 'blue.200')} mt={4}>
                    {t('aboutUsText')}
                </Text>
                <Center height="100px">
                    <Divider />
                </Center>
                <Center marginY="5%">
                    <a onClick={handleShowLoginModal}>
                        <Button colorScheme="teal" variant="outline" color={useColorModeValue('#003552', 'blue.200')} mt={8}>
                            {t('navBarcontact')}
                        </Button>
                    </a>
                </Center>
                <Modal isOpen={showLoginModal} onClose={handleCloseLoginModal}>
                    <ModalOverlay
                        style={{
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(4px)",
                        }}
                    />
                    <ModalContent
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "transparent",
                            boxShadow: "none",
                        }}
                    >
                        <ModalBody>
                            <Pform />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Box>
        </Flex>
    );
};

export default AboutUs;
