import { Box,Text, Image} from "@chakra-ui/react";
// @ts-ignore
import map from "../assets/map.png";


import { useTranslation } from 'react-i18next';

const Implantations = () => {
    const { t} = useTranslation();
    return (
        <Box textAlign="center" bg="#003552" color="white" py={8} px={4}>
            <Text fontSize={{ base: "4xl", md: "6xl" }} textAlign="center" my={4}>
                {t('ImplantationsTitre')}
            </Text>
            <Box mx={"auto"} my={4} width={"90%"} mt={4} mb={4}>
                <Image src={map} alt="Carte des implantations" mx="auto" w="100%" objectFit="contain" />
            </Box>
        </Box>
    );
};

export default Implantations;
