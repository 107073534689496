import React from "react";
import { HStack, Link, Box } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

const NavLinks: React.FC = () => {
    const { t } = useTranslation();

    const navLinks = [
        { label: t("navBaraboutUs"), href: "#aboutUs" },
        { label: t("navBarProduits"), href: "#Produits" },
        { label: t("navBarServices"), href: "#Services" },
        { label: t("navBarcontact"), href: "#contact" },
    ];

    return (
        <HStack spacing={8} alignItems="flex-start">
            {navLinks.map((link) => (
                <Link
                    key={link.label}
                    px={2}
                    py={1}
                    rounded="md"
                    _hover={{
                        textDecoration: "none",
                    }}
                    href={link.href}
                >
                    <Box
                        as="span"
                        position="relative"
                        color="#575757"
                        _after={{
                            content: '""',
                            position: "absolute",
                            width: "100%",
                            height: "2px",
                            bg: "#032c3d",
                            bottom: "-80%",
                            left: 0,
                            transform: "scaleX(0)",
                            transformOrigin: "bottom right",
                            transition: "transform 0.3s ease-out",
                        }}
                        _hover={{
                            _after: {
                                transform: "scaleX(1)",
                                transformOrigin: "bottom left",
                            },
                            color: "#032c3d",
                        }}
                    >
                        {link.label}
                    </Box>
                </Link>
            ))}
        </HStack>
    );
};

export default NavLinks;
